// PrivacyPolicy.js
import React from 'react';
import './PrivacyPolicy.css'; // Importing CSS for styling

const PrivacyPolicy = () => {
  return (
    <div className="container my-5">
      <h1 className="text-center">Privacy Policy</h1>
      <p>
        Effective Date: [October 12, 2024]
      </p>
      <p>
        At KOA Electric, we are committed to protecting your privacy. This privacy policy explains how we collect, use, and safeguard your information when you visit our website.
      </p>

      <h2>Information Collection</h2>
      <p>
        We collect personal information such as your name, email address, and phone number when you fill out forms on our site. We may also collect non-personal information, such as your IP address and browser type.
      </p>

      <h2>How Information is Used</h2>
      <p>
        We use the information we collect to provide our services, communicate with you, and improve our website.
      </p>

      <h2>Information Sharing</h2>
      <p>
        We do not sell or trade your personal information. We may share your information with third parties only when necessary to provide our services or comply with the law.
      </p>

      <h2>Cookies</h2>
      <p>
        Our website uses cookies to enhance user experience. You can manage your cookie preferences through your browser settings.
      </p>

      <h2>Data Security</h2>
      <p>
        We take data security seriously and implement various measures to protect your information. However, no method of transmission is completely secure.
      </p>

      <h2>User Rights</h2>
      <p>
        You have the right to access, correct, or delete your personal information. To exercise these rights, please contact us.
      </p>

      <h2>Third-Party Links</h2>
      <p>
        Our website may contain links to other websites. We are not responsible for their privacy practices.
      </p>

      <h2>Children's Privacy</h2>
      <p>
        We do not knowingly collect information from children under the age of 13.
      </p>

      <h2>Changes to This Privacy Policy</h2>
      <p>
        We may update our privacy policy from time to time. We will notify you of any changes by posting the new policy on this page.
      </p>

      <h2>Contact Us</h2>
      <p>
        If you have any questions about this privacy policy, please contact us at [koaelectric66@gmail.com].
      </p>
    </div>
  );
};

export default PrivacyPolicy;

