import React, { useState, useEffect } from "react";
import "./WhyUs.css"; // Ensure you have the relevant styles for this component

const words = ["fast", "efficient", "reliable"];

const WhyUs = () => {
  const [currentWordIndex, setCurrentWordIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentWordIndex((prevIndex) => (prevIndex + 1) % words.length);
    }, 2000); // Change the word every 2 seconds

    return () => clearInterval(intervalId); // Cleanup interval on component unmount
  }, []);

  return (
    <section className="elementor-section elementor-inner-section elementor-section-boxed elementor-section-height-default">
      <div className="elementor-container elementor-column-gap-default">
        <div className="elementor-column elementor-col-100">
          <div className="elementor-widget-wrap elementor-element-populated">
            <div className="elementor-element elementor-widget text-editor">
              <div className="elementor-widget-container">
                <h2>Why us</h2>
              </div>
            </div>
            <div className="elementor-element elementor-widget premium-addon-fancy-text">
              <div className="elementor-widget-container">
                <div className="premium-fancy-text-wrapper typing">
                  <span className="premium-prefix-text">
                    <span>We take pride in providing <br /></span>
                  </span>
                  {/* Animated Words */}
                  {words.map((word, index) => (
                    <span 
                      key={index} 
                      className={`word ${index === currentWordIndex ? 'visible' : ''}`}
                    >
                      {word}
                    </span>
                  ))}
                  <span className="typed-cursor">|</span>
                  <span className="premium-suffix-text">
                    <span> electrical repair services </span>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WhyUs;



