import React from 'react';
import "./About.css"; // Ensure this file is styled appropriately with Bootstrap

const About = () => {
  return (
    <div className="container2">
      <h2>About KOA Electric</h2>
      <p>
        KOA Electric has been providing expert electrical services for over 20 years. 
        We pride ourselves on delivering top-quality commercial and residential electrical solutions.
      </p>
      
      <h3>Our Services</h3>
      <ul>
          <li>Commercial Light Control System</li>
          <li>Electrical Construction</li>
          <li>Electrical Repairs and Maintenance</li>
          <li>Electrical Remodeling</li>
          <li>Electrical Switch Gear Installation</li>
          <li>Electrical Troubleshooting Repair</li>
          <li>Residential & Commercial Lighting Design and Installation</li>
          <li>New Home Wiring</li>
          <li>Electrical Safety Inspections</li>
          <li>Emergency Electrical Services</li>
          <li>Panel Upgrades and Conduit Work</li>
          <li>Smart Home Installation</li>
          <li>Whole House Surge Protectors</li>
          <li>EV Charger Installation</li>
          <li>Retailers and Store Installation</li>
        </ul>
      
      <h3>Our Mission</h3>
      <p>
        Our mission is to provide safe, reliable, and efficient electrical services that exceed our clients' expectations. 
        We are committed to using the best materials and practices to ensure long-lasting results.
      </p>

      <h3>Our Values</h3>
      <ul>
        <li><strong>Integrity:</strong> We conduct our business with honesty and transparency.</li>
        <li><strong>Quality:</strong> We are dedicated to providing high-quality services and workmanship.</li>
        <li><strong>Customer Satisfaction:</strong> Our customers are our priority, and we strive to meet their needs.</li>
        <li><strong>Safety:</strong> We prioritize safety in every project, ensuring compliance with industry standards.</li>
      </ul>
      
      <h3>Why Choose Us?</h3>
      <p>
        With our experienced team of certified electricians, we guarantee professional and friendly service. 
        Whether it’s a small repair or a large installation, KOA Electric is your trusted partner for all your electrical needs.
      </p>
    </div>
  );
}

export default About;


