import React from "react";
import "./Footer.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook, faInstagram } from "@fortawesome/free-brands-svg-icons";
import logo from "../assets/images/logo.png";

const Footer = () => {
  return (
    <footer className="footer bg-dark text-light py-4">
      <div className="container">
        <div className="row text-center text-md-left">
          {/* Company Information */}
          <div className="col-md-4 mb-3">
            <img
              src={logo}
              alt="KOA Electric Logo"
              className="footer__logo mb-2"
            />
            <h5 className="footer__heading">KOA Electric</h5>
            <p>
              Experienced professionals ensuring your safety and satisfaction.
            </p>
            <p>
              &copy; {new Date().getFullYear()} KOA Electric. All Rights
              Reserved.
            </p>
          </div>

          {/* Navigation Links */}
          <div className="col-md-4 mb-3">
            <h4 className="footer__subheading">Quick Links</h4>
            <ul className="list-unstyled">
              <li className="footer__li">
                <a href="/" className="footer__link">
                  Home
                </a>
              </li>
              <li className="footer__li">
                <a href="/services" className="footer__link">
                  Services
                </a>
              </li>
              <li className="footer__li">
                <a href="/about" className="footer__link">
                  About
                </a>
              </li>
              <li className="footer__li">
                <a href="/contact" className="footer__link">
                  Contact
                </a>
              </li>
            </ul>
          </div>

          {/* Social Media Links */}
          <div className="col-md-4 mb-3">
            <h4 className="footer__subheading">Follow Us</h4>
            <ul className="list-unstyled d-flex justify-content-center justify-content-md-start">
              <li className="footer__social footer__li2 mx-2">
                <a
                  href="https://www.facebook.com/profile.php?id=61567179503477"
                  target="_blank"
                  rel="noreferrer"
                  className="footer__link"
                >
                  <FontAwesomeIcon icon={faFacebook} size="2x" />
                </a>
              </li>
              <li className="footer__social footer__li2 mx-2">
                <a
                  href="https://www.instagram.com/koaelectric66/"
                  target="_blank"
                  rel="noreferrer"
                  className="footer__link"
                >
                  <FontAwesomeIcon icon={faInstagram} size="2x" />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <p>© 2024 KOA Electric. All Rights Reserved.</p>
      <a href="/privacy-policy">Privacy Policy</a>
    </footer>
  );
};

export default Footer;
