import React from "react";
import "./Home.css"; // Ensure you have the styles in this file

// Import images at the top
import project1 from "../assets/images/project1.jpeg";
import project2 from "../assets/images/project2.jpeg";
import project3 from "../assets/images/project3.jpeg";
import project4 from "../assets/images/project9.jpeg";
import aboutUsImage from "../assets/images/about-image.jpeg"; // About section image
import truckImage from "../assets/images/truck.jpeg"; // Import truck image
import logo from "../assets/images/logo.png"; // KOA Electric logo
import { Link } from "react-router-dom"; // Import Link for navigation
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"; // Import Font Awesome
import { faPhone } from "@fortawesome/free-solid-svg-icons"; // Import phone icon
import Contact from "./Contact";
import WhyUs from "./WhyUs";

const Home = () => {
  return (
    <div className="home-container">
      {/* Top Black Banner */}
      <div className="top-banner">
        <p>
          <FontAwesomeIcon icon={faPhone} /> Main:{" "}
          <a href="tel:2405842751">240-584-2751</a> | Cell:{" "}
          <a href="tel:2406949984">240-694-9984</a>
        </p>
      </div>

      {/* Hero Section */}
      <div className="hero-section">
        <div className="hero-overlay">
          <h1>Welcome to KOA Electric</h1>
          <p>Reliable Commercial & Residential Electrical Services</p>
          <a href="/services" className="btn btn-warning">
            Our Services
          </a>
        </div>
      </div>

      {/* About Us Section */}
      <section className="about-section my-5 text-center">
        <h2>About KOA Electric</h2>
        <div className="container">
          <div className="row">
            {/* Left side: About Image and Logo */}
            <div className="col-md-6 d-flex flex-column align-items-center">
              <img
                src={aboutUsImage} // Use the imported image
                alt="KOA Electric Team"
                className="img-fluid rounded mb-3" // Added margin-bottom for spacing
              />
              <img
                src={logo} // Use the imported logo image
                alt="KOA Electric Logo"
                className="img-fluid logo-img"
                style={{ maxWidth: "450px" }} // Increased max width
              />
            </div>

            {/* Right side: About Text */}
            <div className="col-md-6 text-start">
              <p>
                KOA Electric has been providing top-quality electrical services
                to both commercial and residential clients for over 10 years. We
                are dedicated to offering professional, safe, and efficient
                solutions for all your electrical needs.
              </p>
              <h3>Our Mission</h3>
              <p>
                Our mission is to deliver exceptional electrical services that
                ensure safety and reliability for our clients. We strive to
                exceed expectations through our commitment to quality
                workmanship and customer satisfaction.
              </p>
              <h3>Our Values</h3>
              <ul className="list-unstyled">
                <li>
                  Integrity: We uphold the highest standards of honesty and
                  transparency in all our dealings.
                </li>
                <li>
                  Quality: We believe in delivering quality services that stand
                  the test of time.
                </li>
                <li>
                  Customer Focus: Our clients are our top priority, and we work
                  hard to meet their needs.
                </li>
                <li>
                  Innovation: We stay updated with the latest technologies and
                  practices in the electrical industry.
                </li>
              </ul>
              <h3>Meet Our Team</h3>
              <p>
                Our team consists of licensed and experienced electricians who
                are passionate about their work. Each member of KOA Electric
                undergoes rigorous training and continuous education to stay
                ahead in the field.
              </p>
              <h3>Why Choose Us?</h3>
              <p>
                - <strong>Experienced Professionals:</strong> With a decade of
                experience, we bring expertise and knowledge to every project.
                <br />- <strong>24/7 Emergency Services:</strong> We offer
                round-the-clock services for urgent electrical issues, ensuring
                you’re never left in the dark.
                <br />- <strong>Customer Satisfaction Guarantee:</strong> We
                take pride in our work and offer a satisfaction guarantee on all
                services provided.
              </p>
              <a href="/about" className="btn btn-warning">
                Learn More
              </a>
            </div>
          </div>
        </div>
      </section>

      {/* Services Section */}
      <section className="services-section my-5 text-center">
        <h2>Our Services</h2>
        <div className="row">
          <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
            <div className="service-item border rounded p-3">
              <h3>Residential Services</h3>
              <p>
                We handle all types of residential electrical work, including
                wiring, lighting installation, panel upgrades, and more.
              </p>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
            <div className="service-item border rounded p-3">
              <h3>Commercial Services</h3>
              <p>
                Our commercial services cover office buildings, warehouses,
                retail stores, and more. We ensure your business runs smoothly
                with safe and reliable electrical systems.
              </p>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
            <div className="service-item border rounded p-3">
              <h3>Emergency Services</h3>
              <p>
                We offer 24/7 emergency electrical services to resolve any
                urgent issues and ensure your safety.
              </p>
            </div>
          </div>
        </div>
        <a href="/services" className="btn btn-warning">
          Explore Services
        </a>
      </section>

      {/* Call Us Section */}
      <section
        className="call-us-section my-5"
        style={{
          backgroundImage: `url(${truckImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          height: "600px", // Set a specific height for larger screens
          padding: "50px 20px",
          backgroundRepeat: "no-repeat",
          margin: "0 auto",
        }}
      >
        <div className="container h-100" style={{ height: "100%" }}>
          <div className="row h-100">
            <div
              className="col-md-6 offset-md-6 d-flex align-items-center"
              style={{ position: "relative", height: "100%" }}
            >
              <div
                className="text-container"
                style={{
                  backgroundColor: "rgba(128, 128, 128, 0.7)", // Transparent gray background
                  padding: "20px",
                  borderRadius: "8px",
                  height: "100%", // Set height to match the section height
                  display: "flex", // Use flexbox to center content
                  flexDirection: "column", // Stack elements vertically
                  justifyContent: "center", // Center vertically
                  color: "white", // Change text color for better contrast
                }}
              >
                <h2
                  style={{
                    fontFamily: "Anton, sans-serif",
                    fontSize: "4.5rem",
                    fontWeight: "bold",
                  }}
                >
                  CALL US TODAY!
                </h2>
                <p
                  style={{
                    fontFamily: "Anton, sans-serif",
                    fontSize: "2.5rem",
                    fontWeight: "bold",
                    textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)", // Add shadow effect
                  }}
                >
                  <a
                    href="tel:2405842751"
                    style={{ color: "gold", textDecoration: "none" }} // Change textDecoration to 'none' for better visibility
                  >
                    240-584-2751
                  </a>
                </p>
                <h2>koaelectric66@gmail.com</h2>
                <p>

                  If you have any electrical needs or emergencies, don’t
                  hesitate to reach out to us.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

       {/* Testimonials Section */}
       <section className="testimonials-section my-5">
        <h2 className="text-center">What Our Clients Say</h2>
        <div className="testimonial-item text-center">
          <p>
            "KOA Electric provided excellent service! They were quick to respond
            and fixed our electrical issue in no time."
          </p>
          <h5>- Sarah J.</h5>
        </div>
        <div className="testimonial-item text-center">
          <p>
            "Professional and friendly staff! I highly recommend them for
            residential electrical work."
          </p>
          <h5>- Mike T.</h5>
        </div>
        <div className="testimonial-item text-center">
          <p>
            "KOA Electric handled our commercial project with great attention to
            detail. Very satisfied!"
          </p>
          <h5>- Lisa K.</h5>
        </div>
      </section>

      {/* Portfolio Section */}
      <section className="portfolio-section my-5 text-center">
        <h2>Our Portfolio</h2>
        <p>
          Take a look at some of our recent projects that showcase our expertise
          and commitment to quality.
        </p>
        <div className="container">
          <div className="portfolio-scroll">
            <div className="portfolio-item">
              <img
                src={project2}
                alt="Project 1"
                className="img-fluid rounded"
              />
              <h3>Project 1: Residential Wiring Upgrade</h3>
              <p>
                Successfully upgraded the electrical wiring of a historic home,
                enhancing safety and efficiency while preserving the building's
                charm. The project included installing modern circuit breakers
                and energy-efficient lighting solutions to reduce energy costs.
              </p>
            </div>
            <div className="portfolio-item">
              <img
                src={project1}
                alt="Project 2"
                className="img-fluid rounded"
              />
              <h3>Project 2: Commercial Lighting Installation</h3>
              <p>
                Designed and implemented a comprehensive lighting system for a
                local retail store. The project involved installing
                energy-efficient LED fixtures to create a welcoming atmosphere
                while maximizing product visibility and reducing energy
                consumption.
              </p>
            </div>
            <div className="portfolio-item">
              <img
                src={project3}
                alt="Project 3"
                className="img-fluid rounded"
              />
              <h3>Project 3: EV Car Charger Installation</h3>
              <p>
                Led the installation of an EV car charger system for a
                residential client, significantly enhancing their vehicle
                charging convenience and reducing dependency on public charging
                stations. This project included system design, installation, and
                post-installation monitoring to ensure optimal performance and
                client satisfaction.
              </p>
            </div>
            <div className="portfolio-item">
              <img
                src={project4}
                alt="Project 3"
                className="img-fluid rounded"
              />
              <h3>Project 4: Electrical Power Distribution System Installation</h3>
              <p>
              This project involves the installation of an electrical power 
              distribution system within an industrial facility. The image 
              showcases a newly installed transformer and associated electrical 
              panel. Heavy-duty conduit runs are used to route power safely to the 
              transformer and other equipment.
              </p>
            </div>
          </div>

          {/* See More Button */}
          <div className="mt-4">
            <Link to="/portfolio" className="btn btn-gradient">
              View More Projects
            </Link>
          </div>
        </div>
      </section>

      {/* Contact Us Section */}
          <Contact />        
      {/* Whyus */}
      <WhyUs />
    </div>
  );
};

export default Home;

