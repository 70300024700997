import React from "react";
import "bootstrap/dist/css/bootstrap.min.css"; // Ensure Bootstrap is imported
import ServiceBg from "../assets/images/Service-bg.jpg"; // Import the background image
import "./Services.css"; // Ensure you have the styles in this file

const Services = () => {
  return (
    <div
      className="container my-5"
      style={{
        backgroundImage: `url(${ServiceBg})`,
        backgroundSize: "cover", // Cover the entire container
        backgroundPosition: "center",
        padding: "20px",
        borderRadius: "8px",
        color: "white",
      }}
    >
      <h2 className="text-center mb-4">Our Services</h2>
      <div className="row">
        {/* Service 1 */}
        <div className="col-md-4 col-sm-6 col-xs-12">
          <div
            className="elementor-widget-wrap elementor-element-populated"
            style={{
              color: "white",
              padding: "15px",
              borderRadius: "8px",
            }}
          >
            <div className="elementor-widget-container">
              <div
                style={{
                  width: "60px",
                  height: "60px",
                  border: "2px solid rgb(204, 153, 0)", // Dark yellow border
                  borderRadius: "8px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginBottom: "10px",
                }}
              >
                <img
                  decoding="async"
                  width="60"
                  height="60"
                  src="https://elesa.peerduck.com/wp-content/uploads/2023/10/ammeter.png"
                  alt="Service 1"
                  className="img-fluid"
                />
              </div>
            </div>
            <h5>Troubleshooting and Diagnostics</h5>
            <p>
              We offer transparent pricing and free estimates, so you
              know exactly what to expect before we get to work.
            </p>
          </div>
        </div>

        {/* Service 2 */}
        <div className="col-md-4 col-sm-6 col-xs-12">
          <div
            className="elementor-widget-wrap elementor-element-populated"
            style={{
              color: "white",
              padding: "15px",
              borderRadius: "8px",
            }}
          >
            <div className="elementor-widget-container">
              <div
                style={{
                  width: "60px",
                  height: "60px",
                  border: "2px solid rgb(204, 153, 0)",
                  borderRadius: "8px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginBottom: "10px",
                }}
              >
                <img
                  decoding="async"
                  width="60"
                  height="60"
                  src="https://elesa.peerduck.com/wp-content/uploads/2023/10/soldering.png"
                  alt="Service 2"
                  className="img-fluid"
                />
              </div>
            </div>
            <h5>Repairs and Rewiring</h5>
            <p>Expert services in troubleshooting and repair for all electrical issues.</p>
          </div>
        </div>

        {/* Service 3 */}
        <div className="col-md-4 col-sm-6 col-xs-12">
          <div
            className="elementor-widget-wrap elementor-element-populated"
            style={{
              color: "white",
              padding: "15px",
              borderRadius: "8px",
            }}
          >
            <div className="elementor-widget-container">
              <div
                style={{
                  width: "60px",
                  height: "60px",
                  border: "2px solid rgb(204, 153, 0)", 
                  borderRadius: "8px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginBottom: "10px",
                }}
              >
                <img
                  decoding="async"
                  width="60"
                  height="60"
                  src="https://elesa.peerduck.com/wp-content/uploads/2023/10/cord.png"
                  alt="Service 3"
                  className="img-fluid"
                />
              </div>
            </div>
            <h5>Lighting Installation</h5>
            <p>Professional installation for residential and commercial lighting.</p>
          </div>
        </div>

        {/* Service 4 */}
        <div className="col-md-4 col-sm-6 col-xs-12">
          <div
            className="elementor-widget-wrap elementor-element-populated"
            style={{
              color: "white",
              padding: "15px",
              borderRadius: "8px",
            }}
          >
            <div className="elementor-widget-container">
              <div
                style={{
                  width: "60px",
                  height: "60px",
                  border: "2px solid rgb(204, 153, 0)",
                  borderRadius: "8px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginBottom: "10px",
                }}
              >
                <img
                  decoding="async"
                  width="60"
                  height="60"
                  src="https://elesa.peerduck.com/wp-content/uploads/2023/10/toolbox.png"
                  alt="Service 4"
                  className="img-fluid"
                />
              </div>
            </div>
            <h5>Emergency Services</h5>
            <p>We offer 24/7 emergency electrical services for urgent issues.</p>
          </div>
        </div>

        {/* Service 5 */}
        <div className="col-md-4 col-sm-6 col-xs-12">
          <div
            className="elementor-widget-wrap elementor-element-populated"
            style={{
              color: "white",
              padding: "15px",
              borderRadius: "8px",
            }}
          >
            <div className="elementor-widget-container">
              <div
                style={{
                  width: "60px",
                  height: "60px",
                  border: "2px solid rgb(204, 153, 0)",
                  borderRadius: "8px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginBottom: "10px",
                }}
              >
                <img
                  decoding="async"
                  width="60"
                  height="60"
                  src="https://elesa.peerduck.com/wp-content/uploads/2023/10/solar.png"
                  alt="Service 5"
                  className="img-fluid"
                />
              </div>
            </div>
            <h5>Electrical Panel Upgrades</h5>
            <p>Upgrade your electrical panels for improved efficiency and safety.</p>
          </div>
        </div>

        {/* Service 6 */}
        <div className="col-md-4 col-sm-6 col-xs-12">
          <div
            className="elementor-widget-wrap elementor-element-populated"
            style={{
              color: "white",
              padding: "15px",
              borderRadius: "8px",
            }}
          >
            <div className="elementor-widget-container">
              <div
                style={{
                  width: "60px",
                  height: "60px",
                  border: "2px solid rgb(204, 153, 0)",
                  borderRadius: "8px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginBottom: "10px",
                }}
              >
                <img
                  decoding="async"
                  width="60"
                  height="60"
                  src="https://elesa.peerduck.com/wp-content/uploads/2023/10/voltmeter.png"
                  alt="Service 6"
                  className="img-fluid"
                />
              </div>
            </div>
            <h5>Custom Lighting Solutions</h5>
            <p>We provide custom lighting solutions for both residential and commercial spaces.</p>
          </div>
        </div>

        {/* Service 7 */}
        <div className="col-md-4 col-sm-6 col-xs-12">
          <div
            className="elementor-widget-wrap elementor-element-populated"
            style={{
              color: "white",
              padding: "15px",
              borderRadius: "8px",
            }}
          >
            <div className="elementor-widget-container">
              <div
                style={{
                  width: "60px",
                  height: "60px",
                  border: "2px solid rgb(204, 153, 0)",
                  borderRadius: "8px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginBottom: "10px",
                }}
              >
                <img
                  decoding="async"
                  width="60"
                  height="60"
                  src="https://elesa.peerduck.com/wp-content/uploads/2023/10/cord.png"
                  alt="Service 7"
                  className="img-fluid"
                />
              </div>
            </div>
            <h5>Electrical Remodeling</h5>
            <p>Electrical remodeling services for homes and commercial properties.</p>
          </div>
        </div>

        {/* Service 8 */}
        <div className="col-md-4 col-sm-6 col-xs-12">
          <div
            className="elementor-widget-wrap elementor-element-populated"
            style={{
              color: "white",
              padding: "15px",
              borderRadius: "8px",
            }}
          >
            <div className="elementor-widget-container">
              <div
                style={{
                  width: "60px",
                  height: "60px",
                  border: "2px solid rgb(204, 153, 0)",
                  borderRadius: "8px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginBottom: "10px",
                }}
              >
                <img
                  decoding="async"
                  width="60"
                  height="60"
                  src={require('../assets/images/Surge.png')}
                  alt="Service 8"
                  className="img-fluid"
                />
              </div>
            </div>
            <h5>Whole House Surge Protectors</h5>
            <p>Protect your home from electrical surges with advanced systems.</p>
          </div>
        </div>

        {/* Service 9 */}
        <div className="col-md-4 col-sm-6 col-xs-12">
          <div
            className="elementor-widget-wrap elementor-element-populated"
            style={{
              color: "white",
              padding: "15px",
              borderRadius: "8px",
            }}
          >
            <div className="elementor-widget-container">
              <div
                style={{
                  width: "60px",
                  height: "60px",
                  border: "2px solid rgb(204, 153, 0)", // Dark yellow border
                  borderRadius: "8px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginBottom: "10px",
                }}
              >
                <img
                  decoding="async"
                  width="60"
                  height="60"
                  src="https://elesa.peerduck.com/wp-content/uploads/2023/10/ammeter.png"
                  alt="Service 9"
                  className="img-fluid"
                />
              </div>
            </div>
            <h5>Smart Home Installation</h5>
            <p>Installations for smart home technology to enhance modern living.</p>
          </div>
        </div>

       {/* Commercial Service 1 */}
<div className="col-md-4 col-sm-6 col-xs-12">
  <div
    className="elementor-widget-wrap elementor-element-populated"
    style={{
      color: "white",
      padding: "15px",
      borderRadius: "8px",
    }}
  >
    <div className="elementor-widget-container">
      <div
        style={{
          width: "60px",
          height: "60px",
          border: "2px solid rgb(204, 153, 0)",
          borderRadius: "8px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginBottom: "10px",
        }}
      >
        <img
          decoding="async"
          width="60"
          height="60"
          src={require('../assets/images/Commericial.png')}
          alt="Commercial Service 1"
          className="img-fluid"
        />
      </div>
    </div>
    <h5>Commercial Electrical Installations</h5>
    <p>Expert installation of electrical systems for offices, retail spaces, and industrial settings.</p>
  </div>
</div>


        {/* Commercial Service 2 */}
        <div className="col-md-4 col-sm-6 col-xs-12">
          <div
            className="elementor-widget-wrap elementor-element-populated"
            style={{
              color: "white",
              padding: "15px",
              borderRadius: "8px",
            }}
          >
            <div className="elementor-widget-container">
              <div
                style={{
                  width: "60px",
                  height: "60px",
                  border: "2px solid rgb(204, 153, 0)",
                  borderRadius: "8px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginBottom: "10px",
                }}
              >
                <img
                  decoding="async"
                  width="60"
                  height="60"
                  src={require('../assets/images/Highvolt.png')}
                  alt="Commercial Service 2"
                  className="img-fluid"
                />
              </div>
            </div>
            <h5>Industrial Electrical Systems</h5>
            <p>Specialized services for industrial electrical needs, including high-voltage systems and safety compliance.</p>
          </div>
        </div>

        {/* Commercial Service 3 */}
        <div className="col-md-4 col-sm-6 col-xs-12">
          <div
            className="elementor-widget-wrap elementor-element-populated"
            style={{
              color: "white",
              padding: "15px",
              borderRadius: "8px",
            }}
          >
            <div className="elementor-widget-container">
              <div
                style={{
                  width: "60px",
                  height: "60px",
                  border: "2px solid rgb(204, 153, 0)", 
                  borderRadius: "8px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginBottom: "10px",
                }}
              >
                <img
                  decoding="async"
                  width="60"
                  height="60"
                  src={require('../assets/images/Lights.png')}
                  alt="Commercial Service 3"
                  className="img-fluid"
                />
              </div>
            </div>
            <h5>Commercial Lighting Systems</h5>
            <p>Installation and maintenance of energy-efficient lighting systems for commercial spaces.</p>
          </div>
        </div>

      </div>
    </div>
  );
};

export default Services;



