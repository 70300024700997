import React from 'react';
import './Portfolio.css';

import project1 from '../assets/images/project1.jpeg';
import project2 from '../assets/images/project2.jpeg';
import project3 from '../assets/images/project3.jpeg';
import project4 from '../assets/images/project4.jpeg';
import project5 from '../assets/images/project5.jpg';
import project6 from '../assets/images/project6.jpeg';
import project7 from '../assets/images/project7.jpeg';
import project8 from '../assets/images/project8.jpeg';

const Portfolio = () => {
  return (
    <div className="portfolio-container">
      <h2>Our Portfolio</h2>
      <p>Explore our recent projects and see the quality of our work!</p>
      <div className="portfolio-items">
        <div className="portfolio-item">
          <h3>Project 1: Residential Wiring</h3>
          <p>This project involved upgrading the wiring in a residential home, ensuring compliance with local codes and safety standards. The result was a safer and more efficient electrical system.</p>
          <img src={project2} alt="Residential Wiring Project" />
        </div>
        <div className="portfolio-item">
          <h3>Project 2: Commercial Lighting Installation</h3>
          <p>We installed a comprehensive lighting system for a new retail store, highlighting products while ensuring energy efficiency. The project included both indoor and outdoor lighting solutions.</p>
          <img src={project1} alt="Commercial Lighting Installation" />
        </div>
        <div className="portfolio-item">
          <h3>Project 3: EV Car Charger Installation</h3>
          <p>Installed an EV car charger for a residential client, improving charging convenience and reducing reliance on public stations. Design, installation, and post-installation monitoring for optimal performance.</p>
          <img src={project3} alt="Solar Panel Installation" />
        </div>
        <div className="portfolio-item">
          <h3>Project 4: Electrical Conduit Installation</h3>
          <p>We provided and installed an electrical conduit system for a local hospital to ensure safe and organized wiring. This project highlighted our expertise in essential infrastructure support.</p>
          <img src={project4} alt="Emergency Generator Installation" />
        </div>
        <div className="portfolio-item">
          <h3>Project 5: Commercial Office Renovation</h3>
          <p>This project included a complete electrical overhaul during a commercial office renovation, enhancing the lighting and power systems for improved efficiency and aesthetics.</p>
          <img src={project5} alt="Commercial Office Renovation" />
        </div>
        <div className="portfolio-item">
          <h3>Project 6: Smart Home System Integration</h3>
          <p>We integrated smart home technology for a client, allowing them to control lighting, security, and HVAC systems remotely. This project showcased our commitment to modern electrical solutions.</p>
          <img src={project6} alt="Smart Home System Integration" />
        </div>
        <div className="portfolio-item">
          <h3>Project 7: Indoor Fireplace and Wall Installation</h3>
          <p>This project involved creating a stunning feature wall with a modern fireplace and sleek TV installation. The electric fireplace provides warmth and ambiance.</p>
          <img src={project7} alt="Outdoor Lighting and Landscape Design" />
        </div>
        <div className="portfolio-item">
          <h3>Project 8: Electrical Conduit Stub-Up</h3>
          <p>We installed an electrical conduit stub-up system, ensuring safe and efficient access points for wiring connections. This project emphasized our commitment to organized and reliable infrastructure.</p>
          <img src={project8} alt="Panel Upgrade for Energy Efficiency" />
        </div>
      </div>
    </div>
  );
};

export default Portfolio;



