import React from "react";
import { BrowserRouter as Router, Route, Routes, Link, NavLink } from "react-router-dom";
import Home from "./pages/Home";
import Services from "./pages/Services";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Portfolio from "./pages/Portfolio"; // Import the Portfolio component
import PrivacyPolicy from "./pages/PrivacyPolicy"; // Import the Privacy Policy component
import NotFound from "./pages/NotFound"; // Import NotFound component
import logo from "./assets/images/logo.png"; // Import your logo as PNG
import "./App.css";
import Footer from "./pages/Footer"; // Import the Footer component

function App() {
  const handleCallNow = () => {
    window.open("tel:+12405842751"); // Updated with the provided phone number
  };

  return (
    <Router>
      <div>
        <nav className="navbar navbar-expand-lg navbar-dark">
          <div className="container">
            <Link
              className="navbar-brand"
              to="/"
              style={{ display: "flex", alignItems: "center" }}
            >
              <img
                src={logo}
                alt="KOA Electric"
                height="60"
                style={{ marginRight: "10px" }}
              />{" "}
              {/* Increased logo height */}
              <span style={{ fontSize: "24px", fontWeight: "bold", fontFamily: "'MADE Sunflower', sans-serif" }}>
                KOA ELECTRIC
              </span>{" "}
              {/* Logo text */}
            </Link>
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarNav"
              aria-controls="navbarNav"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarNav">
              <ul className="navbar-nav ml-auto">
                <li className="nav-item">
                  <NavLink
                    className={({ isActive }) => `nav-link ${isActive ? 'active' : ''}`}
                    to="/"
                  >
                    Home
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    className={({ isActive }) => `nav-link ${isActive ? 'active' : ''}`}
                    to="/services"
                  >
                    Services
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    className={({ isActive }) => `nav-link ${isActive ? 'active' : ''}`}
                    to="/about"
                  >
                    About
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    className={({ isActive }) => `nav-link ${isActive ? 'active' : ''}`}
                    to="/portfolio"
                  >
                    Portfolio
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    className={({ isActive }) => `nav-link ${isActive ? 'active' : ''}`}
                    to="/contact"
                  >
                    Contact
                  </NavLink>
                </li>
                <li className="nav-item">
                  <button
                    className="btn btn-warning"
                    onClick={handleCallNow}
                    style={{ marginLeft: "10px" }}
                    aria-label="Call KOA Electric"
                  >
                    Call Now
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </nav>

        {/* Routes for different pages */}
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/services" element={<Services />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/portfolio" element={<Portfolio />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="*" element={<NotFound />} /> {/* Catch-all route */}
        </Routes>

        {/* Footer component */}
        <Footer />
      </div>
    </Router>
  );
}

export default App;


