import React, { useState } from 'react';
import emailjs from '@emailjs/browser';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone } from '@fortawesome/free-solid-svg-icons';

const Contact = () => {
    const [formData, setFormData] = useState({
        to_name: '',  // The recipient's name
        from_name: '', // Sender's name
        message: ''   // The message body
    });

    const [statusMessage, setStatusMessage] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true); // Show loading state

        const serviceID = 'service_u9kfmhh';  // Replace with your actual EmailJS service ID
        const templateID = 'template_k7xr963'; // Replace with your EmailJS template ID
        const userID = 'bS4sY4CmJBOv_zuSc';   // Replace with your EmailJS user ID

        try {
            const result = await emailjs.send(serviceID, templateID, formData, userID, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            console.log('SUCCESS!', result.status, result.text);
            setStatusMessage('Your message has been sent successfully!');
            setFormData({ to_name: '', from_name: '', message: '' }); // Clear form after success
        } catch (error) {
            console.error('FAILED...', error);
            setStatusMessage('Failed to send message. Please try again later.');
        } finally {
            setIsSubmitting(false); // Reset loading state
        }
    };

    return (
        <div className="container mt-5">
            <h2 className="text-center">Contact Us</h2>
            <div className="row justify-content-center">
                <div className="col-lg-6 col-md-8 col-sm-10">
                    <form onSubmit={handleSubmit} className="p-4 border rounded shadow-sm bg-light">
                        <div className="form-group">
                            <label htmlFor="to_name">Recipient's Name</label>
                            <input
                                type="text"
                                className="form-control"
                                id="to_name"
                                name="to_name"
                                value={formData.to_name}
                                onChange={handleChange}
                                placeholder="Enter recipient's name"
                                required
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="from_name">Your Name</label>
                            <input
                                type="text"
                                className="form-control"
                                id="from_name"
                                name="from_name"
                                value={formData.from_name}
                                onChange={handleChange}
                                placeholder="Enter your name"
                                required
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="message">Message</label>
                            <textarea
                                className="form-control"
                                id="message"
                                name="message"
                                value={formData.message}
                                onChange={handleChange}
                                rows="5"
                                placeholder="Enter your message"
                                required
                            ></textarea>
                        </div>
                        <button type="submit" className="btn btn-primary w-100" disabled={isSubmitting}>
                            {isSubmitting ? 'Sending...' : 'Submit'}
                        </button>
                    </form>
                    {statusMessage && (
                        <div className="mt-3">
                            <div className={`alert ${statusMessage.includes('successfully') ? 'alert-success' : 'alert-danger'}`}>
                                {statusMessage}
                            </div>
                        </div>
                    )}
                </div>
            </div>

            {/* Phone Number Section */}
            <div className="mt-4 text-center">
                <h3 style={{ fontFamily: 'Anton, sans-serif' }}>
                    <FontAwesomeIcon icon={faPhone} /> Call Us: <a href="tel:+12405842751">(240) 584-2751</a>
                </h3>
            </div>
        </div>
    );
};

export default Contact;









